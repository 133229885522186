<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="wizards">Are you sure you want to delete the selected wizards?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="$emit('hideDeleteSelectedWizardDialog')"/>
      <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteWizard" />
    </template>
  </Dialog>
</template>

<script>
import WizardService from "@/service/WizardService";

export default {
  name: "SelectedWizardDelete",
  emits: ['hideDeleteSelectedWizardDialog','wizardsDeleted'],
  props: {
    wizards: Array,
    wizardDialog: Boolean
  },
  wizardService: null,

  computed: {
    visible: {
      get() { return this.wizardDialog },
      set(visible) {this.$emit('hideDeleteSelectedWizardDialog', visible)}
    }
  },

  created() {
    this.wizardService = new WizardService();
  },

  methods: {
    deleteWizard() {
      this.wizardService.bulkDeleteWizard(this.wizards).then(response => {
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Wizards deleted', life: 3000});
        this.$emit('wizardsDeleted', response);
      });
    }
  }
}
</script>

<style scoped>

</style>
