<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedWizards || !selectedWizards.length" />
            </div>
          </template>

          <template v-slot:end>
            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="wizards" v-model:selection="selectedWizards" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Manage Wizards</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="title" header="Title" :sortable="true" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Title</span>
              {{slotProps.data.title}}
            </template>
          </Column>
          <Column field="wizard_key" header="Key" :sortable="true" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Key</span>
              {{slotProps.data.wizard_key}}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              {{slotProps.data.status}}
            </template>
          </Column>
          <Column field="country.name" header="Country" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Country</span>
              {{slotProps.data.country.name}}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editWizard(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteWizard(slotProps.data)" />
              <Button icon="pi pi-sliders-h" class="p-button-rounded p-button-warning mt-2" @click="goToSteps(slotProps.data.id)" />
            </template>
          </Column>
        </DataTable>
        <wizard-form :wizard="wizard" :wizard-dialog="wizardDialog" @hide-wizard-dialog="hideDialog" @wizard-saved="wizardSaved"></wizard-form>
        <wizard-delete :wizard="wizard" :wizard-dialog="deleteWizardDialog" @hide-delete-wizard-dialog="hideDeleteWizardDialog" @wizard-deleted="wizardDeleted"></wizard-delete>
        <selected-wizard-delete :wizards="selectedWizards" :wizard-dialog="deleteWizardsDialog" @hide-delete-selected-wizard-dialog="hideDeleteSelectedWizardDialog" @wizards-deleted="wizardsDeleted"></selected-wizard-delete>
      </div>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import WizardService from "@/service/WizardService";
import WizardForm from "@/components/wizard/form/WizardForm";
import WizardDelete from "@/components/wizard/form/WizardDelete";
import SelectedWizardDelete from "@/components/wizard/form/SelectedWizardDelete";

export default {
  components: {SelectedWizardDelete, WizardDelete, WizardForm},
  data() {
    return {
      wizards: null,
      wizardDialog: false,
      deleteWizardDialog: false,
      deleteWizardsDialog: false,
      wizard: {},
      selectedWizards: null,
      filters: {},
    }
  },
  wizardService: null,
  created() {
    this.wizardService = new WizardService();
    this.initFilters();
  },
  mounted() {
    this.loadWizards();
  },
  methods: {
    openNew() {
      this.wizard = {};
      this.wizardDialog = true;
    },

    loadWizards() {
      this.wizardService.getWizards().then(data => this.wizards = data.data);
    },

    wizardSaved() {
      this.hideDialog();
      this.wizard = {};
      this.loadWizards();
    },
    hideDialog() {
      this.wizardDialog = false;
    },

    wizardDeleted() {
      this.hideDeleteWizardDialog();
      this.loadWizards();
    },
    hideDeleteWizardDialog() {
      this.deleteWizardDialog = false;
    },

    wizardsDeleted() {
      this.hideDeleteSelectedWizardDialog();
      this.loadWizards();
    },
    hideDeleteSelectedWizardDialog() {
      this.deleteWizardsDialog = false;
    },


    editWizard(wizard) {
      this.wizard = {...wizard};
      this.wizardDialog = true;
    },
    confirmDeleteWizard(wizard) {
      this.wizard = wizard;
      this.deleteWizardDialog = true;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteWizardsDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },

    goToSteps(id) {
      this.$router.push({
        name: 'wizard-steps',
        params: {
          id: id
        }
      });
    }
  }
}
</script>
