<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Wizard Details" :modal="true" class="p-fluid">
    <div class="field">
      <label for="title">Title</label>
      <InputText id="title" v-model.trim="wizardData.title" required="true" autofocus :class="{'p-invalid': submitted && !wizardData.title}" />
      <small class="p-invalid" v-if="submitted && !wizardData.title">Title is required.</small>
    </div>
    <div class="field">
      <label for="status">Status</label>
      <InputText id="status" v-model.trim="wizardData.status" required="true" autofocus :class="{'p-invalid': submitted && !wizardData.status}" />
      <small class="p-invalid" v-if="submitted && !wizardData.status">Status is required.</small>
    </div>
    <div class="field">
      <label for="description">Description</label>
      <Textarea id="description" v-model="wizardData.description" required="true" rows="3" cols="20" />
    </div>

    <div class="field">
      <label for="country" class="mb-3">Country</label>
      <Dropdown id="country" v-model="wizardData.country_id" :options="countries" option-label="name" placeholder="Select a Country" option-value="id">
      </Dropdown>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideWizardDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveWizard" />
    </template>
  </Dialog>
</template>

<script>
import CountryService from "@/service/CountryService";
import WizardService from "@/service/WizardService";

export default {
  name: "WizardForm",
  emits: ['hideWizardDialog','wizardSaved'],
  props: {
    wizard: Object,
    wizardDialog: Boolean
  },
  countryService: null,
  wizardService: null,
  mounted() {
    this.countryService.fetchAll().then(data => this.countries = data.data);
  },

  computed: {
    visible: {
      get() { return this.wizardDialog },
      set(visible) {this.$emit('hideWizardDialog', visible)}
    },
    wizardData: {
      get() { return this.wizard },
    }
  },

  created() {
    this.countryService = new CountryService();
    this.wizardService = new WizardService();
  },

  data() {
    return {
      submitted: false,
      countries: [],
      wizardVisibleLocal: false,
    }
  },

  methods: {
    saveWizard() {
      this.submitted = true;

      if (this.wizard.id) {
        this.wizardService.updateWizard(this.wizardData).then(response => {
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Wizard updated', life: 3000});
          this.$emit('wizardSaved', response);
        });
      } else {
        this.wizardService.createWizard(this.wizardData).then(response => {
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Wizard created', life: 3000});
          this.$emit('wizardSaved', response);
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
